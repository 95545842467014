import React, { FC, Fragment, useEffect, useState } from 'react';
import { KTSVG} from '../../_metronic/helpers'
import { Puff } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import api from '../api/api'


const FishingCardsSave = (props) => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const delay = ms => new Promise(res => setTimeout(res, ms));

  const onSubmitHandler = (event) => {
    event.preventDefault();

    var formData = {
      "card_id" : event.target.card_id.value ? parseInt(event.target.card_id.value) : event.target.card_id.value,
      "card_price" : event.target.card_price.value ? parseFloat(event.target.card_price.value) : event.target.card_price.value,
      "card_name" : event.target.card_name.value,
      "card_type" : event.target.card_type.value
    }

    if(formData) {
      updateCards(formData)
    } else {
      toast("Die ausgefüllten Daten sind nicht korrekt", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      });
    }
  }


  const updateCards = async (formData) => {
    api.updateFishingCards(formData)
    .then((response)=>{
      if(response.data.status) {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          setIsLoading(false)
          
          
      } else {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    }).catch(function (error) {
      console.log("Error", error);
      toast("Etwas ist schief gelaufen", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      });
    })

    await delay(2000);
    window.location.reload(false);
  }



  if(props) {
      return (
        <div className="modal fade" tabIndex={-1} id={`card_modal_${props.card_id}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">Angelkarte bearbeiten</h2>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                  />
                </div>
              </div>
              <div className="modal-body">

                <form onSubmit={onSubmitHandler} data-card-id={props.card_id}>

                  <div className="mb-10">
                      <label htmlFor="card_name" className="form-label">Kartenname</label>
                      <input
                        name="card_name"
                          type="text"
                          className="form-control form-control-white"
                          defaultValue={props.card_name}
                      />
                  </div>

                  <div className="mb-10">
                      <label htmlFor="card_price" className="form-label">Kartenpreis</label>
                      <input
                          name="card_price"
                          type="text"
                          className="form-control form-control-white"
                          defaultValue={props.card_price}
                      />
                  </div>

                  <input type="text" name="card_type" defaultValue={props.card_type} hidden/>
                  <input type="text" name="card_id" defaultValue={props.card_id} hidden/>

                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Änderungen speichern
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      )
  } else {
      return (
          <div>Keine Benutzerdaten verfügbar</div>
      )
  }
    

}

export {FishingCardsSave}