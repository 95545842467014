/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import { useNavigate } from "react-router-dom";
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'

// const navigate = useNavigate();
// navigate("/angeln/geoeffnete-karten");

import {SidebarMenuItem} from '../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem'

const DashboardPage: FC = () => (

  
  <>


  
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <SidebarMenuItem to='/angeln/geoeffnete-karten' title='Geöffnete Karten' hasBullet={true} />

        <SidebarMenuItem to='/angeln/neue-angelanfrage' title='Neue Angelanfrage' hasBullet={true} />
      </div>
    </div>
    {/* end::Row */}





  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
