import React, { FC, Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { KTSVG} from '../../_metronic/helpers'
import api from '../api/api'
import { toast } from 'react-toastify';
import { Document, Text, Font, Image, StyleSheet, Page } from '@react-pdf/renderer';


const GenerateOrderPdf = (props) => {

  const [pdfData, setPdfData] = useState();

  console.log("props", props.orderId)

  const thenga = "9948729e101711ee8630ac87a303dfa3"

  useEffect(() => {

    const fetchPageData = async () => {
      if(props.orderId) {
        api.getOrderPdfData(props.orderId)
        .then((response)=>{
          if(response.data.status) {


            console.log("response", response.data)
            setPdfData(response.data.data)

            //const LastName = () => `${response.data.data.user_data.user_id}`;

            toast(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          } else {
            toast(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          
        }).catch(function (error) {
          console.log("Error", error);
          toast("Etwas ist schief gelaufen", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        })

      }

    }
    fetchPageData();
  }, [props.orderId]);




  const chakka = "HgHgHgHg"
  // console.log("chakka", chakka)
  // console.log("pdfData", pdfData)
  

  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });




  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald'
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      textAlign: 'center',
      fontFamily: 'Oswald'
    },
    text: {
      margin: 5,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    textUser: {
      margin: 5,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  });


  if(pdfData) {

    return (
      <Document>
        <Page style={styles.body}>
          <Image
            style={styles.image}
            src="/media/logos/fischereipatent_Logo_big.png"
          />
          <Text style={styles.subtitle}>
            www.fischen-in-badragaz.ch
          </Text>

          <Text style={styles.header} fixed>
            Angelkarten-ID ~ {pdfData.order_data.order_id}
          </Text>
          <Text style={styles.title}>{pdfData.order_data.card_name}</Text>
          <Text style={styles.author}>CHF {pdfData.order_data.card_price}</Text>

          <Text style={styles.text}>
            Karte gültig ab : {pdfData.order_data.catch_list_expiry_date_display}
          </Text>
          
          <Text style={styles.text}>
            Karte gültig ab : {pdfData.order_data.validity_start_display}
          </Text>

          <Text style={styles.text}>
            Fischlistenaktualisierung bis : {pdfData.order_data.validity_end_display}
          </Text>

          <Text style={styles.textUser}>
            Vorname : {pdfData.user_data.first_name}
          </Text>
          <Text style={styles.textUser}>
            Nachname : {pdfData.user_data.last_name}
          </Text>
          <Text style={styles.textUser}>
            Email : {pdfData.user_data.email}
          </Text>
          <Text style={styles.textUser}>
            Mobile : {pdfData.user_data.mobile}
          </Text>

          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    )
  
  
  
  
  } else {
    return (
      <h2>PDF wird geladen. . .</h2>
    )
  }


}



// const GenerateOrderPdf = () => (
//   <Document>
//     <Page>
//       <Text>Hello There</Text>
//     </Page>
//   </Document>
// );



export default GenerateOrderPdf