/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import api from '../../api/api'


const UserOpenCards = (props) => {

  const [userOpenCards, setUserOpenCards] = useState();

  useEffect(() => {
    const fetchPageData = async () => {
      api.getUserOpenOrders()
      .then((response)=>{
        setUserOpenCards(response.data.data)
      }).catch(function (error) {
        console.log("Error", error);
      })
    }
    fetchPageData();
  }, []);


  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  }


  if(userOpenCards) {

    return (

      <div>

        <div className={`card ${props.className}`}>
          <div className='card-header border-0'>
            <h3 className='card-title fw-bold text-dark'>gültige Angelkarten</h3>
          </div>
          <div className='card-body pt-2'>
            <table className="table table-row-dashed table-row-gray-300 gy-7">
              <thead>
                  <tr className="fw-bolder fs-6 text-gray-800">
                      <th>bestellt am</th>
                      <th>Kartenname</th>
                      <th>Kartenpreis</th>
                      <th>Karte gültig ab</th>
                      <th>Karte gültig bis</th>
                      <th>Fischlistenaktualisierung bis</th>
                      <th>PDF</th>
                  </tr>
              </thead>
              <tbody>
                { userOpenCards.valid_cards ? userOpenCards.valid_cards.map((record) => (
                    <tr key={record['order_id']} >
                      <td>{record['order_time_display']}</td>
                      <td>{record['card_name']}</td>
                      <td>{record['card_price']}</td>
                      <td>{record['validity_start_display']}</td>
                      <td>{record['validity_end_display']}</td>
                      <td>{record['catch_list_expiry_date_display']}</td>
                      <td>
                        <button type="button" className="btn btn-primary" 
                          role="link"
                          onClick={() => openInNewTab(`/angeln/pdf/${record["order_id"]}`)}
                        >
                          PDF generieren
                        </button>
                      </td>
                    </tr>
                  
                  ))
                  :
                  <div className="pt-5">
                    <h5>Keine gültigen Karten verfügbar</h5>
                  </div>
                }
              </tbody>
            </table>
          </div>
        </div>

        <div className={`card ${props.className}`}>
          <div className='card-header border-0'>
            <h3 className='card-title fw-bold text-dark'>Die Fischliste muss aktualisiert werden</h3>
          </div>
          <div className='card-body pt-2'>
            <table className="table table-row-dashed table-row-gray-300 gy-7">
              <thead>
                  <tr className="fw-bolder fs-6 text-gray-800">
                      <th>bestellt am</th>
                      <th>Kartenname</th>
                      <th>Kartenpreis</th>
                      <th>Karte gültig ab</th>
                      <th>Karte gültig bis</th>
                      <th>Fischlistenaktualisierung bis</th>
                      <th>Fischliste</th>
                  </tr>
              </thead>
              <tbody>
                { userOpenCards.valid_catchlists ? userOpenCards.valid_catchlists.map((record) => (
                    <tr key={record['order_id']} >
                      <td>{record['order_time_display']}</td>
                      <td>{record['card_name']}</td>
                      <td>{record['card_price']}</td>
                      <td>{record['validity_start_display']}</td>
                      <td>{record['validity_end_display']}</td>
                      <td>{record['catch_list_expiry_date_display']}</td>
                      <td>Aktualisieren</td>
                    </tr>
                  
                  ))
                  :
                  <div className="pt-5">
                    <h5>Keine offenen Karten müssen die Fischliste aktualisieren</h5>
                  </div>
                }
              </tbody>
            </table>
          </div>
        </div>

      </div>
     
    )

  } else {
    return (
      <div>Keine Karten verfügbar</div>
    )
  }
}

export {UserOpenCards}
