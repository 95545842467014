import axios from 'axios'
import {getAuthToken} from '../../app/modules/auth'

const API_URL = "http://fischereipatent.eu-central-1.elasticbeanstalk.com"
//const API_URL = "http://127.0.0.1:8000"


// Create instance called instance
const instance = axios.create({
    baseURL: API_URL,
    // headers: {'Authorization': "Bearer "+getAuthToken()}
});


const api = {

    getFishingCards: () =>
    instance({
        'method':'GET',
        'url':'/fishingcards',
        'headers': {'Authorization': "Bearer "+getAuthToken()}
    }),

    getOpenOrders: () =>
    instance({
        'method':'GET',
        'url':'/openorders',
        'headers': {'Authorization': "Bearer "+getAuthToken()}
    }),

    getClosedOrders: () =>
    instance({
        'method':'GET',
        'url':'/closedorders',
        'headers': {'Authorization': "Bearer "+getAuthToken()}
    }),

    getUserOpenOrders: () =>
    instance({
        'method':'GET',
        'url':'/useropenorders',
        'headers': {'Authorization': "Bearer "+getAuthToken()}
    }),


    createNewOrder: (orderData: any) =>
    instance({
        'method':'POST',
        'url':'/createorder',
        'headers': {'Authorization': "Bearer "+getAuthToken(), "Content-Type": "application/json" },
        'data': JSON.stringify(orderData)
    }),

    getOrderPdfData: (orderId: any) =>
    instance({
        'method':'POST',
        'url':'/getorderpdfdata',
        'headers': {'Authorization': "Bearer "+getAuthToken(), "Content-Type": "application/json" },
        'data': JSON.stringify(orderId)
    }),

    updateFishingCards: (cardData: any) =>
    instance({
        'method':'POST',
        'url':'/updatefishingcards',
        'headers': {'Authorization': "Bearer "+getAuthToken(), "Content-Type": "application/json" },
        'data': JSON.stringify(cardData)
    }),


};

export default api;