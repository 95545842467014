import React from "react";
import {CreateNewOrder} from '../../../../src/fishing/models/CreateNewOrder'



export function FishingRequestPage() {
    return (
        <div>
            <h1>Angelkarte bestellen</h1>
            <CreateNewOrder />
        </div>
    )
    
}