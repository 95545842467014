import React from "react";
import {EmployeesList} from '../../../../src/fishing/components/system/EmployeesList'

export function SystemEmployeesPage() {
    return (
        <div className='row'>
            {/* <h1>Hello! This is SystemEmployeesPage </h1> */}
            <div className='col-xl-12'>
                <EmployeesList className='mb-5 mb-xxl-8' />
            </div>
        </div>
    )
}

