import axios from 'axios'
import {getAuthToken} from '../../app/modules/auth'


const API_URL = "http://fischereipatent.eu-central-1.elasticbeanstalk.com"
//const API_URL = "http://127.0.0.1:8000"



// Create instance called instance
const instance = axios.create({
    baseURL: API_URL,
    //headers: {'Authorization': "Bearer "+getAuthToken()}
});



const users = {


    getTest: () =>
    instance({
        'method':'GET',
        'url':'/unprotected',
        'headers': {'Authorization': "Bearer "+getAuthToken()}
    }),

    getUserList: () =>
    instance({
        'method':'GET',
        'url':'/userlist',
        'headers': {'Authorization': "Bearer "+getAuthToken()}
    }),

    getMemberList: () =>
    instance({
        'method':'GET',
        'url':'/memberlist',
        'headers': {'Authorization': "Bearer "+getAuthToken()}
    })

};

export default users;