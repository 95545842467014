/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useState } from 'react';
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()
  const [userAdmin, setUserAdmin] = useState(true);

  useEffect(() => {
    if (currentUser !== undefined) {
      if(currentUser.user_group == "admin") {
        setUserAdmin(true)
      }
    }
  }, [currentUser]);

  return (
    <>

      {/* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}

      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}

      {/* System - Admin Pages */}
      { userAdmin && 
        <>
          <SidebarMenuItemWithSub to='#' title='System' fontIcon='bi-archive' icon='element-plus'>
            <SidebarMenuItem to='system/benutzerliste' title='Benutzerliste' hasBullet={true} />
            <SidebarMenuItem to='system/mitgliederliste' title='Mitgliederliste' hasBullet={true} />
            <SidebarMenuItem to='system/angelkarten' title='Angelkarten' hasBullet={true} />
            <SidebarMenuItem to='verwaltung/geoeffnete-angelanfragen' title='Geöffnete Angelanfragen' hasBullet={true} />
          </SidebarMenuItemWithSub>
    

          {/* <SidebarMenuItemWithSub to='#' title='Verwaltung' fontIcon='bi-archive' icon='profile-circle'>
            <SidebarMenuItem to='verwaltung/geoeffnete-angelanfragen' title='Geöffnete Angelanfragen' hasBullet={true} />
            <SidebarMenuItem to='verwaltung/geschlossene-angelanfragen' title='Geschlossene Angelanfragen' hasBullet={true} />
            <SidebarMenuItem to='verwaltung/ausstehende-angelkarten' title='Ausstehende Angelkarten' hasBullet={true} />
            <SidebarMenuItem to='verwaltung/fischereistatistik' title='Fischereistatistik' hasBullet={true} />
          </SidebarMenuItemWithSub> */}
        </>
      }




      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Dashboard</span>
        </div>
      </div>


      {/* Neue Angelanfrage */}
      <SidebarMenuItem to='/angeln/neue-angelanfrage' fontIcon='bi-archive' icon='profile-circle' title='Neue Angelanfrage' hasBullet={false} />

      {/* Angeln Pages */}
      <SidebarMenuItemWithSub to='#' title='Angeln' fontIcon='bi-archive' icon='profile-circle'>
        <SidebarMenuItem to='/angeln/geoeffnete-karten' title='Geöffnete Karten' hasBullet={true} /> {/* Geöffnete Karten */}
        <SidebarMenuItem to='/angeln/geschlossene-karten' title='Geschlossene Karten' hasBullet={true} /> {/* Geschlossene Karten */}
      </SidebarMenuItemWithSub>


      {/* Profil Pages */}
      {/* <SidebarMenuItemWithSub to='#' title='Profil' fontIcon='bi-archive' icon='profile-circle'>
        <SidebarMenuItem to='/profil/unterlagen' title='Unterlagen' hasBullet={true} />
        <SidebarMenuItem to='/profil/benutzerprofil' title='Benutzerprofil' hasBullet={true} />
      </SidebarMenuItemWithSub> */}



{/* 
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>



      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>



      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
 */}









    </>
  )
}

export {SidebarMenuMain}
