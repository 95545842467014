import React from "react";

import {CustomerList} from '../../../../src/fishing/components/system/CustomerList'

export function SystemCustomersPage() {
    return (
        <div className='row'>
            {/* <h1>Hello! This is SystemCustomersPage </h1> */}
            <div className='col-xl-12'>
                <CustomerList className='mb-5 mb-xxl-8' />
            </div>
        </div>
    )
}
