import React, { FC, Fragment, useEffect, useState } from 'react';
import { KTSVG} from '../../_metronic/helpers'
import api from '../api/api'
import {useAuth} from '../../app/modules/auth'
import { Puff } from  'react-loader-spinner'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";



const CreateNewOrder = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {currentUser, logout} = useAuth()
  const [selOption, setSelOption] = useState({});
  const [fishingCards, setFishingCards] = useState();
  useEffect(() => {
    const fetchPageData = async () => {
      api.getFishingCards()
      .then((response)=>{
        const sortedfishingCards = [...response.data.data].sort((a, b) => a.card_id - b.card_id);
        if(sortedfishingCards) {
          setFishingCards(sortedfishingCards)
          setSelOption(sortedfishingCards[0])
        }
      }).catch(function (error) {
        console.log("Error", error);
      })
    }
    fetchPageData();
  }, []);
  
  const HandelChange = (obj) => {
    if(obj.target.value) {
      if(fishingCards) {
        for (let index in fishingCards) {
          if(fishingCards[index]["card_id"] == obj.target.value) {
            setSelOption(fishingCards[index]);
          }
        }
      }
    }
  }

  const onSubmitHandler = (event) => {
    setIsLoading(true)
    event.preventDefault();
    var formData = {
      "card_id" : event.target.card_id.value ? parseInt(event.target.card_id.value) : event.target.card_id.value,
      "card_price" : event.target.card_price.value ? parseFloat(event.target.card_price.value) : event.target.card_price.value,
      "user_id" : event.target.user_id.value,
      "is_paid" : event.target.is_paid.value,
      "payment_details" : event.target.payment_details.value,
      "catch_list" : event.target.catch_list.value,
      "card_name" : event.target.card_name.value,
      "card_type" : event.target.card_type.value
    }
    if(formData) {
      createOrder(formData)
    } else {
      toast("Die ausgefüllten Daten sind nicht korrekt", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      });
    }

  }

  const createOrder = async (formData) => {
    api.createNewOrder(formData)
    .then((response)=>{
      if(response.data.status) {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          setIsLoading(false)
          navigate("/angeln/geoeffnete-karten");
      } else {
        toast(response.data.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
      }
    }).catch(function (error) {
      console.log("Error", error);
      toast("Etwas ist schief gelaufen", {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      });
    })
  }


  if(fishingCards) {
      return (
        <div  className="py-5 col-md-6 border">
          <form onSubmit={onSubmitHandler} data-card-id="23232">

            <div className="mb-10">
                <label htmlFor="card_id" className="form-label">Angelkarte auswählen</label>
                <select onChange={HandelChange} name="card_id" className="form-select form-select-white" aria-label="Select example">
                {/* <option>Angelkarte auswählen</option> */}
                { fishingCards.map((record, index) => (
                    <option key={record.card_id} value={record.card_id}>{record.card_name}</option>
                  ))
                } 
                </select>
            </div>
              
            { selOption.validity_data &&  
              <div className="mb-10">
                gültig ab <strong>{selOption.validity_data && selOption.validity_data.validity_start_display.split(" ", 2)[0]}</strong> bis <strong>{selOption.validity_data && selOption.validity_data.validity_end_display.split(" ", 2)[0]} </strong>
              </div>
            }

            <div className="mb-10">
                <label htmlFor="card_price" className="form-label">Kartenpreis (CHF)</label>
                <input type="text" name="card_price" className="form-control form-control-white" value={selOption.card_price} disabled />
            </div>

            <input type="text" name="card_name" defaultValue={selOption.card_name} hidden/>
            <input type="text" name="card_type" defaultValue={selOption.card_type} hidden/>
            <input type="text" name="user_id" defaultValue={currentUser.user_id} hidden/>
            <input type="text" name="is_paid" defaultValue={true} hidden/>
            <input type="text" name="payment_details" defaultValue={"weTest"} hidden/>
            <input type="text" name="catch_list" defaultValue={"youTest"} hidden/>

            { isLoading ? 
                <div className="modal-footer">
                  <Puff
                    height="50"
                    width="50"
                    radius={1}
                    color="#009ef7"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              :
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    neue Karte bestellen
                  </button>
                </div>
            }
          </form>
        </div>
      )
  } else {
      return (
          <div>Keine Kartendaten verfügbar</div>
      )
  }
    
}

export {CreateNewOrder}