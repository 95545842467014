import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import {OpenCardsPage} from "../pages/angeln/open-cards"
import {ClosedCardsPage} from "../pages/angeln/closed-cards"
import {FishingRequestPage} from "../pages/angeln/fishing-request"
import {OrderPdfPage} from "../pages/angeln/order-pdf"

import {ProfileDocumentsPage} from "../pages/profil/profile-documents"
import {ProfileUserPage} from "../pages/profil/profile-user"

import {OpenFishingRequestsPage} from "../pages/verwaltung/open-fishing-requests"
import {ClosedFishingRequestsPage} from "../pages/verwaltung/closed-fishing-requests"
import {PendingFishingCardsPage} from "../pages/verwaltung/pending-fishing-cards"
import {AdminFishingReportsPage} from "../pages/verwaltung/admin-fishing-reports"

import {SystemEmployeesPage} from "../pages/system/system-manage-employees"
import {SystemCustomersPage} from "../pages/system/system-manage-customers"
import {SystemFishingCardsPage} from "../pages/system/system-fishing-cards"

const PrivateRoutes = () => {



  //const AngelnPage = lazy(() => import('../modules/angeln/AngelnPage'))

  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        <Route path="/angeln/geoeffnete-karten" element={<OpenCardsPage />} />
        <Route path="/angeln/geschlossene-karten" element={<ClosedCardsPage />} />
        <Route path="/angeln/neue-angelanfrage" element={<FishingRequestPage />} />

        <Route path="/angeln/neue-angelanfrage" element={<FishingRequestPage />} />


        <Route path="/profil/unterlagen" element={<ProfileDocumentsPage />} />
        <Route path="/profil/benutzerprofil" element={<ProfileUserPage />} />

        <Route path="/verwaltung/geoeffnete-angelanfragen" element={<OpenFishingRequestsPage />} />
        <Route path="/verwaltung/geschlossene-angelanfragen" element={<ClosedFishingRequestsPage />} />
        <Route path="/verwaltung/ausstehende-angelkarten" element={<PendingFishingCardsPage />} />
        <Route path="/verwaltung/fischereistatistik" element={<AdminFishingReportsPage />} />

        <Route path="/system/benutzerliste" element={<SystemEmployeesPage />} />
        <Route path="/system/mitgliederliste" element={<SystemCustomersPage />} />
        <Route path="/system/angelkarten" element={<SystemFishingCardsPage />} />


        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>

      <Route
          path='/angeln/pdf/:id'
          element={
            <SuspensedView>
              <OrderPdfPage />
            </SuspensedView>
          }
        />
        
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
