/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import users from '../../api/users'
import { EmployeesListSave } from '../../models/EmployeesListSave';




const CustomerList = (props) => {
    const [memberList, setMemberList] = useState();
    useEffect(() => {
      const fetchPageData = async () => {
        users.getMemberList()
        .then((response)=>{
          setMemberList(response.data.data)
        }).catch(function (error) {
          console.log("Error", error);
        })
      }
      fetchPageData();
    }, []);
  
  
  
    if(memberList) {
  
      return (
    <div className={`card ${props.className}`}>

      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Mitgliederliste</h3>
        
      </div>

      <div className='card-body pt-2'>

      { memberList.map((record) => (

        <div className='d-flex align-items-center mb-7' key={record["user_id"]}>
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='' alt='' />
          </div>
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              {record['first_name']}
            </a>
          </div>
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              {record['last_name']}
            </a>
          </div>
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              {record['user_name']}
            </a>
          </div>
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              {record['email']}
            </a>
          </div>
          <div className='flex-grow-1'>

          <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#kt_modal_${record["user_id"]}`} >Bearbeiten</button>


          <EmployeesListSave
            {...record}
          />

          </div>
        </div>

      ))
    } 





      </div>
    </div>
 )

} else {

  return (
    <div>Keine Benutzer verfügbar</div>
    )
}
}

export {CustomerList}
