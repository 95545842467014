import React from "react";
import {UserOpenCards} from '../../../../src/fishing/components/angeln/UserOpenCards'

export function OpenCardsPage() {
    return (
        <div className='row'>
            {/* <h1>Offene Angelkarten</h1> */}
            <div className='col-xl-12'>
                <UserOpenCards className='mb-5 mb-xxl-8' />
            </div>
        </div>
    )
}
