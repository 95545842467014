import React from "react";

import {AdminOpenCards} from '../../../../src/fishing/components/verwaltung/AdminOpenCards'

export function OpenFishingRequestsPage() {
    return (
        <div className='row'>
            {/* <h1>Offene Angelkarten</h1> */}
            <div className='col-xl-12'>
                <AdminOpenCards className='mb-5 mb-xxl-8' />
            </div>
        </div>
    )
}
