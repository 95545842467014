import React, { FC, Fragment, useEffect, useState } from 'react';
import { KTSVG} from '../../_metronic/helpers'




const EmployeesListSave = (props) => {

  const onSubmitHandler = (event) => {
    event.preventDefault();
    // responseBody.firstName = firstName
    // responseBody.lastName = lastName
    // responseBody.age = age
    // console.log(JSON.stringify(responseBody))
    console.log("Form submit")
  //Form submission happens here
  }

    if(props) {
        return (
            <div className="modal fade" tabIndex={-1} id={`kt_modal_${props.user_id}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title">{props.first_name} {props.last_name}</h2>
                  <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon svg-icon-2x"
                    />
                  </div>
                </div>
                <div className="modal-body">

                  <form onSubmit={onSubmitHandler} data-user-id={props.user_id}>

                    <div className="mb-10">
                        <label className="form-label">Benutzername</label>
                        <input
                            type="text"
                            className="form-control"
                            value={props.user_name}
                            disabled
                        />
                    </div>

                    <div className="mb-10">
                        <label className="form-label">Email</label>
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            value={props.email}
                        />
                    </div>
                    <div className="mb-10">
                        <label className="form-label">Vorname</label>
                        <input
                            type="text"
                            className="form-control form-control-white"
                            value={props.first_name}
                        />
                    </div>

                    <div className="mb-10">
                        <label className="form-label">Nachname</label>
                        <input
                            type="text"
                            className="form-control form-control-white"
                            value={props.last_name}
                        />
                    </div>

                    <div className="mb-10">
                        <label className="form-label">Geburtsdatum</label>
                        <input
                            type="text"
                            className="form-control form-control-white"
                            value={props.date_of_birth}
                        />
                    </div>

                    <div className="mb-10">
                        <label className="form-label">Strasse</label>
                        <input
                            type="text"
                            className="form-control form-control-white"
                            value={props.street}
                        />
                    </div>

                    <div className="mb-10">
                        <label className="form-label">PLZ</label>
                        <input
                            type="text"
                            className="form-control form-control-white"
                            value={props.postal}
                        />
                    </div>

                    <div className="mb-10">
                        <label className="form-label">Stadt</label>
                        <input
                            type="text"
                            className="form-control form-control-white"
                            value={props.city}
                        />
                    </div>

                    <div className="mb-10">
                        <label className="form-label">Land</label>
                        <input
                            type="text"
                            className="form-control form-control-white"
                            value={props.country}
                        />
                    </div>

                    <div className="mb-10">
                        <label className="form-label">Telefon</label>
                        <input
                            type="text"
                            className="form-control form-control-white"
                            value={props.phone}
                        />
                    </div>

                    <div className="mb-10">
                        <label className="form-label">Mobile</label>
                        <input
                            type="text"
                            className="form-control form-control-white"
                            value={props.mobile}
                        />
                    </div>

                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Änderungen speichern
                      </button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        )
    } else {
        return (
            <div>Keine Benutzerdaten verfügbar</div>
        )
    }
    

}

export {EmployeesListSave}