/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import api from '../../api/api'
import { FishingCardsSave } from '../../models/FishingCardsSave';



const FishingCardList = (props) => {
  const [fishingCards, setFishingCards] = useState();
  useEffect(() => {
    const fetchPageData = async () => {
      api.getFishingCards()
      .then((response)=>{
        const sortedfishingCards = [...response.data.data].sort((a, b) => a.card_id - b.card_id);
        setFishingCards(sortedfishingCards)

      }).catch(function (error) {
        console.log("Error", error);
      })
    }
    fetchPageData();
  }, []);

  // console.log("fishingCards", fishingCards)

  if(fishingCards) {
    return (
      <div className={`card fishing-cards ${props.className}`}>

        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-dark'>Angelkarten</h3>
        </div>

        <div className='card-body pt-2'>

          { fishingCards.map((record, index) => (
            <div className='d-flex align-items-center mb-8' key={index} >
              <span className={`bullet bullet-vertical h-40px bg-success side-bg${index}`}></span>

              <div className='padding-left-10 flex-grow-1'>
                <div className='text-gray-800 text-hover-primary fw-bold fs-6'>
                  {record.card_id} ) {record.card_name} | CHF {record.card_price}
                </div>
              </div>

              
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#card_modal_${record.card_id}`} >Bearbeiten</button>

              <FishingCardsSave
                {...record}
              />

            </div>
            ))
          } 

        </div>
      </div>
    )
  } else {
    return (
        <div>Keine Benutzer verfügbar</div>
      )
    }
}



export {FishingCardList}
