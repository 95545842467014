import React from "react";
import {FishingCardList} from '../../../../src/fishing/components/system/FishingCardList'

export function SystemFishingCardsPage() {
    return (
        <div className='row'>
            {/* <h1>Hello! This is SystemFishingCardsPage </h1> */}
            <div className='col-xl-12'>
                <FishingCardList className='mb-5 mb-xxl-8' />
            </div>
        </div>
    )
}
