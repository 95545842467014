import React, { FC, Fragment, useEffect, useState } from 'react';
import {Route, Routes, Navigate} from 'react-router-dom'
import GenerateOrderPdf from '../../../fishing/models/GenerateOrderPdf';
import { PDFViewer } from '@react-pdf/renderer';
import { useParams } from "react-router-dom";


export function OrderPdfPage() {
  const urlParam = useParams()
  const [orderIdReady, setOrderidReady] = useState(false);
  useEffect(() => {
    if (urlParam !== undefined) {
      setOrderidReady(true)
    }
  }, [urlParam]);

  if(orderIdReady) {
    return (
      <PDFViewer className="pdf-height">
        <GenerateOrderPdf orderId={urlParam.id} />
      </PDFViewer>
    )
  } else {
    return (
      <div>Keine Benutzerdaten verfügbar</div>
    )
  }

}